<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin stretch-card">
                            <div class="card">
                                <div class="card-header">
                                     <h4 class="card-title">{{ $t('Survey Summary') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row mt-2 ml-3">
                                        <div class="col-md-5 col-sm-12">
                                            <div class="row" style="align-items: center;">
                                                <div class="col-md-3">
                                                    <h6 style="margin: 0;">{{ $t('Survey Name') }}</h6>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-select v-model="form.survey_id" @change="editSurveySummary" aria-describedby="input-1-live-feedback" :options="surveys" value-field="id" text-field="name">
                                                        <b-form-select-option v-if="surveys.length === 0" :disabled = true selected>{{ $t('No Survey Found') }}</b-form-select-option>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback id="input-1-live-feedback">
                                                        {{ $t("field-required") }}
                                                    </b-form-invalid-feedback>
                                                </div>
                                            </div>
                                            <div class="row mt-3" style="align-items: center;">
                                                <div class="col-md-3">
                                                    <h6 style="margin: 0;">{{ $t('Survey Start') }}</h6>
                                                </div>
                                                <div class="col-md-6">
                                                        <b-form-input id="survey_start" type="datetime-local" v-model="form.survey_start" @input="calculateTime"></b-form-input>
                                                </div>
                                            </div>
                                            <div class="row mt-3" style="align-items: center;">
                                                <div class="col-md-3">
                                                    <h6 style="margin: 0;">{{ $t('Survey End') }}</h6>
                                                </div>
                                                <div class="col-md-6">
                                                        <b-form-input id="survey_end" type="datetime-local" v-model="form.survey_end" @input="calculateTime"></b-form-input>
                                                </div>
                                            </div>
                                            <div class="row mt-3" style="align-items: center;">
                                                <div class="col-md-3">
                                                    <h6 style="margin: 0;">{{ $t('Total Time') }}</h6>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-input id="total_time" autocomplete="total_time" :placeholder="$t('Total Time')" type="text" v-model="form.total_time" readonly></b-form-input>
                                                </div>
                                            </div>
                                            <div class="row mt-3" style="align-items: center;">
                                                <div class="col-md-3">
                                                    <h6 style="margin: 0;">{{ $t('Team / Spotters') }}</h6>
                                                </div>
                                                <div class="col-md-6">
                                                        <multiselect v-model="selectedUser" tag-placeholder="Add this as new tag" :placeholder="$t('Search or add a tag')" label="name" track-by="id" :options="users" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="row mb-3" v-for="(question, index) in form.custom_question" :key="index" style="align-items: center;">
                                                <div class="col-md-6">
                                                    <div v-if="form.custom_question.length > 0">
                                                        <div>
                                                            <h6 style="margin: 0;">{{ question.question }}</h6>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div v-if="form.custom_question.length > 0">
                                                        <div>
                                                            <div v-if="question.answer_type === 'D'" style="display: block;">
                                                                <b-form-select v-model="model.question.selectedAnswerId[question.id]">
                                                                    <option  v-for="(response, responseIndex) in question.custom_field_responses" :key="responseIndex" :value="response.answer">{{ response.answer }}</option>
                                                                </b-form-select>
                                                            </div>
                                                            <div v-if="question.answer_type === 'I'" style="display: block;">
                                                                <div v-for="(response, responseIndex) in question.custom_field_responses" :key="responseIndex">
                                                                    <b-form-input name="checkbox-answer" :placeholder="$t('Type Possible Answers....')" v-model="response.answer"></b-form-input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <p>{{ $t('No survey questions found for the selected survey') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <b-button :disabled="isLoading2" type="submit" variant="success"  class="btn float-right btn-success">
                                                <template v-if="isLoading2">
                                                    <i class="fas fa-spinner fa-spin"></i>{{$t('submitting')}}...
                                                </template>
                                                <template v-else>
                                                    <span>{{ $t('submit-btn') }}</span>
                                                </template>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('spots') }}</h4>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                         <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading" @on-search="searchFn" :search-options="{enabled: true,searchFn: searchFn}"
                            :pagination-options="{
                                enabled: true,
                                dropdownAllowAll: false,
                                perPageDropdown: [10, 20, 50, 100, 200],
                            }" :rows="rows" :columns="columns" :line-numbers="true">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'action'" class="text-nowrap">
                                    <b-button
                                        size="sm"
                                        @click="editSpot(props.row.id,props.row.spot_type)"
                                        class="mr-1 btn-info text-white"
                                    >{{ $t('edit-btn') }}</b-button>
                                    <b-button
                                        size="sm"
                                        @click="deleteClientSpot(props.row.id)"
                                        class="mr-1 btn-danger text-white"
                                    >{{ $t('delete-btn') }}</b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        </form>
    </section>
</template>
<script>
import Vue from "vue";
import API from "@/api";
import Snotify, {
    SnotifyPosition
} from "vue-snotify";
import {
    validationMixin
} from "vuelidate";
import moment from "moment";
import {required, minLength, email, requiredIf, sameAs, between} from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect'
const options = {
    toast: {
        position: SnotifyPosition.rightTop
    }
};
Vue.use(Snotify, options);
export default {
    name: "Users",
    mixins: [validationMixin],
    components: {
            Multiselect
        },
    data() {
        return {
            currentAudioIndex: -1,
            version_id:null,
            model:{

                question:{
                    selectedAnswerId : {},
                    
                }
            },
            form: {
                id: "",
                client_id: JSON.parse(localStorage.getItem("client_id")),
                client_url: "",
                survey_id: "",
                survey_start: "",
                survey_end: "",
                total_time: '',
                custom_question:[],
                custom_question_id:'',
                custom_answer_id:'',
            },
            serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    survey_id: null,
                    columnFilters: {},
                    sort: [{
                        field: 'id',
                        type: 'desc'
                    }],
                    page: 1,
                    perPage: 10
                },
                columns: [
                {
                    label: "Spot ID",
                    field: "spot_id",
                    filterable: true
                },
                {
                    label: "User",
                    field: "user_name",
                    filterable: true
                },
                {
                    label: "Type",
                    field: "spot_name",
                    sortable: true
                },
                {
                    label: "Location",
                    field: "location_name",
                    sortable: true
                },
                {
                    label: "Date Spotted",
                    field: "spot_date",
                    sortable: true,
                    formatFn: this.formatDate
                },
                {
                    label: "Type",
                    field: "media_types",
                    sortable: true,
                    formatFn: this.formatType
                },
                {
                    label: "Action",
                    field: "action",
                    sortable: false
                }
            ],
            isLoading: false,
            rows: [],
            totalRecords: 0,
            categories: [],
            users:[],
            selectedUser:[],
            surveys: [], 
            client_id: JSON.parse(localStorage.getItem("client_id")),
        };
    },
    validations: {
        form: {
        }
    },
    beforeCreate() {},
    beforeMount() {
        // this.getSpot();
    },
    async mounted() {
        this.getAllSurveys();
        this.getClientUsers();
        this.fetchSurveyQuestionsAndAnswers();
        this.loadItems();
        this.editSurveySummary();
    },
  created() {
    this.getAppSettings();
  },
    updated() {
        // this.form.questions.push();
    },
    methods: {
        loadItems() {
            this.isLoading = true;
            API.getSurveySummary(this.serverParams,
                data => {
                    this.isLoading = false;
                    this.totalRecords = data.totalRecords;
                    this.rows = data.data.data;
                    this.rows.forEach(q => {
                        const data = {
                            id: q.id,

                        };
                        this.custom_fields.push(data);
                    });
                },
                err => {
                }
            )
        },
        onSubmit() {
                this.$v.form.$touch();
                this.isDisabled = true;
                const selectedAnswers = [];
                this.form.custom_question.forEach(question => {
                    if (question.answer_type === 'D' && this.model.question.selectedAnswerId[question.id]) {
                        const selectedAnswerId = this.model.question.selectedAnswerId[question.id];
                        const selectedResponse = question.custom_field_responses.find(response => response.answer === selectedAnswerId);

                        if (selectedResponse) {
                            selectedAnswers.push({
                                question_id: question.id,
                                answer_id: selectedResponse.answer,
                                text_answer: selectedResponse.answer,
                                answer_type: 'D'
                            });
                        }
                    } 
                        else if (question.answer_type === 'I') {
                        question.custom_field_responses.forEach(response => {
                            if (response.answer) {
                                selectedAnswers.push({
                                    question_id: question.id,
                                    answer_id: response.id,
                                    text_answer: response.answer,
                                    answer_type: 'I'
                                });
                            }
                        });
                    }
                });

                const data = {
                    ...this.form,
                    selectedUser: this.selectedUser,
                    selectedAnswers: selectedAnswers
                };

                API.addEditSurveySummary(
                data,
                data => {
                    if (data.status === 200) {
                        this.$snotify.success(data.message, {
                            timeout: 1000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true
                        }).on("destroyed", () => this.$router.push({ name: "" }));
                    } else {
                        this.$snotify.error(data.message);
                    }
                    this.isDisabled = false;
                },
                err => {
                    this.isDisabled = false;
                    this.$snotify.success(err, {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            );
        },
        deleteSurveySummary(e) {
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    let data = {
                        id: e
                    }
                    API.deleteSurveySummary(
                        e,
                        data => {
                            this.loadItems();
                            this.$snotify.success('Record has been deleted.');
                        },
                        err => {})
                }
            })
        },
        deleteClientSpot(e) {
            this.$swal({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then(result => {
                if (result.value) {
                    let data = {
                        id: e
                    };
                    API.deleteClientSpot(
                        e,
                        data => {
                            this.loadItems();
                            this.$snotify.success('Spot deleted successfully.')
                        },
                        err => {}
                    );
                }
            });
        },
        editSurveySummary() {
            const selectedSurveyId = this.form.survey_id;
            this.serverParams.survey_id = this.form.survey_id;
            console.log(' this.serverParams.survey_id  :',  this.serverParams.survey_id );
            if (selectedSurveyId) {
                API.getSurveySummaryData(
                    selectedSurveyId,
                    data => {
                        console.log('data', data);
                        this.resetForm();
                        const surveyData = data.surveySummary[selectedSurveyId];
                        console.log('surveyData',surveyData);
                        if (surveyData && Array.isArray(surveyData)) {
                            surveyData.forEach(question => {
                                this.form.survey_id = question.survey_id;
                                this.form.survey_start = question.start_date;
                                this.form.survey_end = question.end_date;
                                this.form.total_time = question.total_time;
                                if(question.answer_type === 'D'){
                                    // this.model.question.selectedAnswerId =  question.custom_answer_id;
                                    this.$set(this.model.question.selectedAnswerId, question.custom_question_id, question.custom_answer_id);
                                }
                                // if(question.answer_type === 'D'){
                                    this.form.custom_question.forEach(cquestion =>{
                                        // if(cquestion.answer_type === 'D' && cquestion.id === parseInt(question.custom_question_id)){
                                        //     cquestion.custom_field_responses.forEach(response => {
                                        //         if(response.id === parseInt(question.custom_question_id)){
                                        //             response.selectedAnswerId = question.custom_answer_id;
                                        //             console.log('response.selectedAnswerId :', response.selectedAnswerId);
                                        //         }
                                        //     }); 
                                        // }
                                        if(cquestion.answer_type === 'I' && cquestion.id === parseInt(question.custom_question_id)){
                                            cquestion.custom_field_responses.forEach(response => {
                                                response.answer = question.custom_answer_id;
                                            });
                                        }
                                    });
                                // }
                                // if(question.answer_type === 'I'){
                                    // this.form.custom_question.forEach(cquestion =>{
                                    //     if(cquestion.answer_type == 'I' && cquestion.id === parseInt(question.custom_question_id)){
                                    //         cquestion.custom_field_responses.forEach(response => {
                                    //             response.answer = question.custom_answer_id;
                                    //         });
                                    //     }
                                    // });
                                // }
                            });
                        }
                        else{
                            this.resetForm();
                            // if(surveyData === undefined){
                            //     this.model.question.selectedAnswerId = [];
                            // }
                        }
                        this.selectedUser = data.surveySummary.selectedUser;
                        this.loadItems();
                    },
                    err => {
                        console.error('Error fetching survey summary:', err);
                    }
                );
            }
        },
        editSpot(e, type) {
            this.$router.push({
                name: "edit_spots",
                params: { id: e, type: type }
            });
        },
        resetForm() {
            this.form.id = '';
            // this.form.survey_id = '';
            // this.form.custom_question = '';
            this.form.survey_start = '';
            this.form.survey_end = '';
            this.form.total_time = '';
            this.selectedUser = '';
            this.form.custom_question.forEach(question => {
                this.model.question.selectedAnswerId = [];
                if(question.answer_type === 'I'){
                    question.custom_field_responses.forEach(response => {
                        response.selectedAnswerId = '';
                        response.answer = '';
                    });
                }
            });
            this.$v.form.$reset();
        },
        formatType(mediaTypes) {
            if (mediaTypes !== null) {
                if (mediaTypes.includes("image")) {
                    if(mediaTypes.includes("audio")) {
                        return "Photo / Audio";
                    } else {
                        return "Image";
                    }
                } else if (mediaTypes.includes("audio")) {
                    return "Audio ";
                } else {
                    return "None";
                }
            } else {
                return "";
            }
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params[0].type,
                    field: params[0].field,
                }],
            });
            this.loadItems();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        searchFn(params) {
            console.log('okkok')
            this.updateParams({searchTerm: params});
            this.loadItems();
            this.isLoading = false;
        },
        getAllSurveys() {
            const data = {
                client_id: JSON.parse(localStorage.getItem('client_id')),
            }
            API.getAllSurveys(data,
                data => {
                    this.surveys = data.data;
                    if (this.surveys.length > 0) {
                        this.form.survey_id = this.surveys[0].id;
                        this.editSurveySummary();
                    }
                },
                err => {}
            );
        },
        getClientUsers() {
            const data = {
                client_id: JSON.parse(localStorage.getItem('client_id')),
            }
            API.getClientUsers(data,
                data => {
                    this.users = data.data;
                },
                err => {}
            );
        },
        calculateTime() {
            const start = new Date(this.form.survey_start);
            const end = new Date(this.form.survey_end);

            if (!isNaN(start) && !isNaN(end)) {
                const diff = Math.abs(end - start); 
                const hours = Math.floor(diff / (1000 * 60 * 60));
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

                const formattedHours = String(hours).padStart(2, '0');
                const formattedMinutes = String(minutes).padStart(2, '0');
                
                this.form.total_time = `${formattedHours}:${formattedMinutes}`;
            } else {
                this.form.total_time = '';
            }
        },
        fetchSurveyQuestionsAndAnswers() {
            const clientId = JSON.parse(localStorage.getItem('client_id'));
            API.getCustomFieldQuestionAnswers(
                clientId,
                data => {
                    this.form.custom_question = data.data;
                },
                error => {
                    console.error('Error fetching Question Answers:', error);
                }
            );
        },
    },
    computed: {}
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
/* your passed-in element */
.enlargeable-image>.slot {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    cursor: zoom-in;
}
/* default img if no element passed in */
.enlargeable-image>.slot>img.default {
    max-width: 100%;
    vertical-align: middle;
}
/* passed-in element when growth is happening */
.enlargeable-image.active>.slot {
    opacity: 0.3;
    filter: grayscale(100%);
}
/* full version that grows (background image allows seamless transition from thumbnail to full) */
.enlargeable-image .full {
    cursor: zoom-out;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
}
.enlargeable-image .full>img {
    object-fit: contain;
    width: 0;
    height: 0;
    display: none !important;
}
/* full version while getting bigger */
.enlargeable-image .full.enlarging {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: zoom-out;
    z-index: 3;
}
/* full version while at its peak size */
.enlargeable-image .full.enlarged {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: zoom-out;
    z-index: 2;
}
/* full version while getting smaller */
.enlargeable-image .full.delarging {
    display: flex;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: zoom-in;
    z-index: 1;
}
.enlargeable-image .full.enlarged img {
    display: none !important;
    background-color: red;
    width: 100%;
    height: 100%;
}
.row .card .new-card-body {
    padding: 1.25rem 1.437rem !important;
}
.edit-tab-body {
    margin: 0px;
}
@media (max-width: 375px) {
    .row .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
}
</style>
